/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef,useLayoutEffect, useEffect} from "react";
import { useGLTF } from "@react-three/drei";

import * as THREE from 'three'
import { useFrame, useLoader } from "@react-three/fiber";

import {useBox, useRaycastVehicle} from '@react-three/cannon'
import {useWheels} from './Wheelswork.js'
import {Quaternion, Vector3} from 'three'
import {useControls} from './useControls.js'
import { WheelDebug } from "./WheelDebug.js";



export default function Monaco({thirdPerson}) {
  let ref = useRef();
  const { scene, nodes, materials } = useGLTF('./model/MonacoSportsCar.glb')

  useLayoutEffect(() => {
    scene.traverse((obj) => obj.type === 'Mesh' && (obj.receiveShadow = obj.castShadow = true))
  Object.assign(nodes.MainBody.material,{ color : new THREE.Color("black"),
  roughness : 0.1,
  metalness : 0.9,
  envMapIntensity : 0.75,})
  Object.assign(nodes.FrontBody.material,{ color : new THREE.Color("red"),
  roughness : 0.6,
  metalness : 0.6,
  envMapIntensity : 0.75,})
  Object.assign(nodes.HeadLight.material,{roughness : 0.1,
    metalness : 0.9,
    envMapIntensity : 0.75,
  emissive :new THREE.Color("lightblue"),
  emissiveIntensity: 10, toneMapped: false })
  Object.assign(nodes.BackLight.material,{roughness : 0 ,
    metalness : 0.9,
  emissive :new THREE.Color("red"),
  emissiveIntensity: 100, toneMapped: false })
  Object.assign(nodes.FrontLines.material,{roughness : 0 ,
    metalness : 0.9,
  emissive :new THREE.Color("white"),
  emissiveIntensity: 100, toneMapped: false })
  Object.assign(nodes.UpperLines.material,{roughness : 0 ,
    metalness : 0.9,
  emissive :new THREE.Color("white"),
  emissiveIntensity: 100, toneMapped: false })
  Object.assign(nodes.OFrontLight.material,{roughness : 0 ,
    metalness : 0.9,
  emissive :new THREE.Color("white"),
  emissiveIntensity: 100, toneMapped: false })
  Object.assign(nodes.frontlightMirror.material,{roughness : 0 ,
    opacity: 0.55,color : "blue",
  transparent: true})
  Object.assign(nodes.OBackLight.material,{roughness : 0 ,
    metalness : 0.9,
  emissive :new THREE.Color("white"),
  emissiveIntensity: 100, toneMapped: false })
  Object.assign(nodes.FrontLight.material,{roughness : 0 ,
    metalness : 0.9,
  emissive :new THREE.Color("white"),
  emissiveIntensity: 100, toneMapped: false })
  Object.assign(nodes.BackLight.material,{roughness : 0 ,
    metalness : 0.9,
  emissive :new THREE.Color("white"),
  emissiveIntensity: 100, toneMapped: false })

  Object.assign(nodes.FrontMirror.material,{ color: new THREE.Color("black"),
  opacity: 0.15,
  transparent: false,})
  Object.assign(nodes.SideFirstMirror.material,{ color:new THREE.Color("black"),
  opacity: 0.1,
  transparent: false,})
  Object.assign(nodes.SidesecondMirror.material,{ color:new THREE.Color("black"),
  opacity: 0.1,
  transparent: false,})
  Object.assign(nodes.SidethirdMirror.material,{ color:new THREE.Color("black"),
  opacity: 0.1,
  transparent: false,})
  Object.assign(nodes.Windshield.material,{ color:new THREE.Color("black"),
  opacity: 0.1,
  transparent: false,})
  Object.assign(nodes.BackMirror.material,{ color:new THREE.Color("red"),
  opacity: 0.25,
  transparent: true,})
  Object.assign(nodes.BackLowerMirror.material,{ color:new THREE.Color("red"),
  opacity: 0.85,
  transparent: false,})
 
 }, [scene, nodes, materials])

const position = [0,1, 0];
const width = 0.15 ; 
const height = 0.07 ; 
const front = 0.15 ; 
const wheelRadius = 0.05 ;
const chassisBodyArgs = [width , height , front*2];
const [chassisBody,chassisApi] = useBox(
  ()=>({
    args : chassisBodyArgs,
    mass : 150 , 
    position ,
  }), useRef(null),
);
const [wheels,wheelInfos] = useWheels(width,height , front , wheelRadius);
 const [vehicle,vehicleApi] = useRaycastVehicle(() => ({
  chassisBody , 
   wheelInfos,
   wheels
 }), useRef(null),
 );
 useControls(vehicleApi,chassisApi);
 useFrame((state)=>{
  if(!thirdPerson) return;
  let position = new Vector3(0,0,0);
  position.setFromMatrixPosition(chassisBody.current.matrixWorld);
  let quaternion = new Quaternion(0,0,0,0);
  quaternion.setFromRotationMatrix(chassisBody.current.matrixWorld);
  let Dir = new Vector3(0,0,-100);
  Dir.applyQuaternion(quaternion);
  Dir.normalize();
  let cameraPosition = position.clone().add(
    Dir.clone().multiplyScalar(1).add(
      new Vector3(5,3,8)
    )
  );
  Dir.add(new Vector3(0,3,0));
  state.camera.position.copy(cameraPosition);
  state.camera.lookAt(position);
});
useEffect(()=>{
scene.scale.set(0.15,0.15,0.15);
},[scene]
);

  return (
(
  <group ref ={vehicle} name = "vehicle">

    <group ref = {chassisBody} name = "chassisBody">
       <primitive object={scene} />
    </group>
    
  <WheelDebug wheelRef = {wheels[0]} radius = {wheelRadius} />
 
  <WheelDebug wheelRef = {wheels[1]} radius = {wheelRadius} />
  <WheelDebug wheelRef = {wheels[2]} radius = {wheelRadius} />
  <WheelDebug wheelRef = {wheels[3]} radius = {wheelRadius} />
  </group>
  )

  );
}


useGLTF.preload("./carmodel/MonacoSportsCar.glb");
