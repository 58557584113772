import { createRoot } from "react-dom/client"
import Experience from './Experience.js'
import {Canvas} from '@react-three/fiber'
import {OrbitControls} from '@react-three/drei'
import { Physics} from "@react-three/cannon"
import './Loading.css'
import './styles.css'
import { Suspense } from "react"
import Phone from './Phone.js'
import MapAd from './MapsPhone.js'
import Monaco from './MonacoCar.js'
import Collider from './ColliderBox.js'

const root = createRoot(document.querySelector('#root'));
const pos1 = Math.random()*10;
const pos2 = Math.random()*10;
const pos3 = Math.random()*10;

root.render(
<>
<Suspense fallback ={<span ><div class="body">
    <div class = "container">
        <div class ="anim">
        <span ></span>
        <span ></span>
         <span ></span>
          <span ></span>
           <span ></span>
            <span ></span>
             <span ></span>
              <span ></span>
              <span ></span>
              
              <span ></span>
              <span ></span>
              <span ></span>
              <span ></span>
              <span ></span>
              <span ></span>
              <span ></span>
              <span ></span>
              <span ></span>
              <span ></span>
              
              <span ></span>
              <span ></span>
        </div>
       <div class="text">
        <h1><center>Loading</center></h1><br />
        <h3>(1) W , A , S , D to move the car.</h3>
        <h3>(2) R to Respawn.</h3>
        <h3>(3) Arrow Keys to escape Ground.</h3>
        <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/"><img alt="Creative Commons License"  src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png" /></a><br />This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License</a>
       </div>
     

    </div>
</div>
</span>}>
<Canvas shadows >
    <Physics broadphase="SAP" gravity={[0,-2.5,0]}>
<color args={['#15151a']} />
<Experience></Experience>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>
<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>
<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>

<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>
<Collider rotation={[0,0,0]} position= {[pos1,pos2,pos3]}/>


{/* <CollidingBoxes position = {[6,1,0]} rotation = {[45,90,90]} scale = {[1,1]}/>

<CollidingBoxes position = {[7,1,0]} rotation = {[45,90,90]} scale = {[1,1]}/>

<CollidingBoxes position = {[8,1,0]} rotation = {[45,90,90]} scale = {[1,1]}/>

<CollidingBoxes position = {[9,1,0]} rotation = {[45,90,90]} scale = {[1,1]}/> */}
</Physics>
<Phone args={[2,2]} />
<MapAd args = {[2,2]} />
</Canvas>
</Suspense>
</>

)